import React from 'react';

import Template from './Template';

export default function App() {
    return (
        <Template>
            <div className="container-fluid">
                CONTEUDO
            </div>
        </Template>
    )
}
